/* ----------------------------------------------------------------------------------------------------------------- *\
    Bootstrap
\* ----------------------------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------------------------- *\
    Font Awesome
\* ----------------------------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------------------------- *\
    Menu
\* ----------------------------------------------------------------------------------------------------------------- */
ul.nav-tabs .nav-link {
  background-color: transparent;
  border: 0 solid;
  border-bottom-width: 4px;
  border-bottom-color: transparent;
  color: #fff;
}

ul.nav-tabs .nav-link.active, ul.nav-tabs .nav-link:hover.active {
  font-weight: bold;
  color: #33b5e5;
  border-bottom-color: #33b5e5;
  border-bottom-width: 4px;
  background-color: transparent;
}

ul.nav-tabs .nav-link:hover {
  transition-duration: 0ms;
  border-bottom-color: #ced4da;
  border-bottom-width: 4px;
}

.tabs-container .tab-content {
  padding: 20px;
  border: 1px solid #444;
  background: #222;
  border-top: 0;
}

nav.navbar a.dropdown-toggle {
  white-space: nowrap;
}

nav.navbar .dropdown-menu .input-group {
  margin: 0.25rem 1.5rem;
  width: calc(100% - 3rem);
  min-width: 200px;
}

nav.navbar .dropdown-menu .input-group input {
  border: 0;
}

.page-item.info .page-link {
  color: #fff;
  background-color: #444;
  white-space: nowrap;
}

form > legend {
  border-bottom: 2px solid #444;
}

a, button, input[type="submit"] {
  transition-duration: 200ms;
}

body {
  min-width: 320px;
}

.title {
  display: flex;
  position: relative;
  width: calc(100% + 40px);
  left: -20px;
  top: -20px;
  height: 74px;
  border-bottom: 4px solid #ecbb13;
  padding: 17px 20px 0;
}

h1 {
  font-family: "Open Sans Condensed", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  font-size: 2rem;
  color: white;
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
}

h3.logo {
  margin-bottom: 0;
  text-align: center;
}

h3.logo img {
  height: 60px;
}

.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
}

.ace_editor {
  font-family: "Source Code Pro", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  background-color: transparent;
  border-radius: 0.25rem;
  border: 1px solid #262424;
}

.khq-sticky {
  position: sticky;
  z-index: 100;
  top: 0;
}

@media (min-width: 992px) {
  .khq-nav input, .khq-nav button, .khq-nav select, .khq-nav .bootstrap-select {
    margin-right: 0.5rem;
  }
}

@media (max-width: 991.98px) {
  .khq-nav input, .khq-nav button, .khq-nav select {
    margin-top: 0.5rem;
  }
  .khq-nav .navbar-toggler {
    margin-top: 0;
  }
  .khq-nav input, .khq-nav .bootstrap-select, .khq-nav select {
    width: 100% !important;
  }
  .khq-nav button.btn-primary {
    display: block;
    width: 100%;
  }
  .khq-nav .btn-group {
    width: 100%;
  }
}

.khq-nav .btn-group button {
  margin-right: 0;
}

.ace_editor ::-webkit-scrollbar, .table-responsive ::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
}

.ace_editor ::-webkit-scrollbar-track, .table-responsive ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.ace_editor ::-webkit-scrollbar-thumb, .table-responsive ::-webkit-scrollbar-thumb {
  background: rgba(100, 100, 100, 0.9);
}

.ace_editor ::-webkit-scrollbar-corner,
.ace_editor ::-webkit-scrollbar-thumb:window-inactive, .table-responsive ::-webkit-scrollbar-corner,
.table-responsive ::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(100, 100, 100, 0.6);
}

#content {
  padding: 20px 20px 65px;
  min-height: 100vh;
  transition: none;
}

@media (min-width: 768px) {
  #content {
    width: calc(100% - 250px);
  }
}

@media (min-width: 768px) {
  #content {
    margin-left: 250px;
  }
}

@media (max-width: 767.98px) {
  #content {
    width: 100%;
  }
}

#content.no-side-bar {
  width: 100%;
  margin-left: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.turbolinks-progress-bar {
  background-color: #d44a3a;
  box-shadow: 0 1px 10px #d44a3a;
}

form.khq-form {
  margin-bottom: 25px;
}

form.khq-form .bootstrap-select {
  width: 100% !important;
}

form.khq-form div.khq-submit,
#content aside {
  position: fixed;
  bottom: 0;
  left: 0;
  background: red;
  padding: 5px 15px;
  background: #000;
  border-top: 1px solid #444;
  display: flex;
  justify-content: flex-end;
  width: calc(100vw - 15px);
  z-index: 1030;
}

@media (min-width: 768px) {
  form.khq-form div.khq-submit,
  #content aside {
    left: 250px;
    width: calc(100vw - 250px - 15px);
  }
}

form.khq-form div.khq-submit > div,
#content aside > div {
  flex: 1;
}

form.khq-form div.khq-submit > div:first-child,
#content aside > div:first-child {
  flex-grow: 2;
}

form.khq-form div.khq-submit > div:last-child,
#content aside > div:last-child {
  text-align: right;
}

form.khq-form div.khq-submit .btn,
#content aside .btn {
  white-space: nowrap;
}

.tab-pane form.khq-form div.khq-submit {
  width: calc(100vw - 15px);
}

@media (min-width: 768px) {
  .tab-pane form.khq-form div.khq-submit {
    width: calc(100vw - 250px - 15px);
  }
}

.khq-login {
  width: 100%;
  max-width: 330px;
  margin: 0 auto;
  text-align: center;
  padding: 40px;
  background: #222;
  box-shadow: 0 0 40px 5px rgba(0, 95, 129, 0.4);
}

.khq-login > div:first-child {
  margin-bottom: 40px;
}

#khq-sidebar {
  min-width: 250px;
  max-width: 250px;
  background: #000;
  color: #fff;
  transition: all 0.3s;
  font-family: "Open Sans Condensed", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1.09375rem;
  border-right: 1px solid #444;
  z-index: 2;
  display: flex;
  flex-direction: column;
}

#khq-sidebar .sidebar-header {
  padding: 5px 10px;
  background: #005f81;
  border-bottom: 4px solid #ecbb13;
  position: relative;
}

#khq-sidebar .sidebar-header a:hover {
  text-decoration: none;
}

#khq-sidebar .sidebar-header .version {
  position: absolute;
  right: 5px;
  top: 3px;
  font-size: 0.76562rem;
}

#khq-sidebar ul {
  flex-grow: 2;
}

#khq-sidebar ul p {
  color: #fff;
  padding: 10px;
}

#khq-sidebar ul li a {
  padding: 10px;
  display: block;
  color: #FFFFFF;
}

#khq-sidebar ul li a:hover {
  background: #0085b4;
  text-decoration: none;
}

#khq-sidebar ul li.active > a {
  color: #fff;
  background: #005f81;
}

#khq-sidebar ul li ul {
  border-bottom: 1px solid #0097ce;
}

#khq-sidebar ul li ul li a {
  background: #000;
}

#khq-sidebar ul li ul li a.active {
  background: #ecbb13;
  color: #000;
}

#khq-sidebar a[aria-expanded="true"] {
  color: #fff;
  background: #005f81;
}

#khq-sidebar a[data-toggle="collapse"] {
  position: relative;
}

#khq-sidebar .dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

#khq-sidebar ul ul a {
  font-size: 0.9em !important;
  padding-left: 30px !important;
  background: #005f81;
}

#khq-sidebar .sidebar-log {
  padding: 5px 10px;
  background: #005f81;
  border-top: 1px solid #ecbb13;
}

#khq-sidebar .sidebar-log a {
  color: white;
}

#khq-sidebar .sidebar-log a:hover {
  text-decoration: none;
}

@media (max-width: 767.98px) {
  #khq-sidebar {
    margin-left: -250px;
  }
  #khq-sidebar.active {
    margin-left: 0;
  }
  #khq-sidebar #khq-sidebar-collapse span {
    display: none;
  }
}

@media (min-width: 768px) {
  #khq-sidebar {
    position: fixed;
    height: 100%;
  }
}

.khq-data-filter > nav {
  display: flex;
  order: 2;
}

@media (max-width: 991.98px) {
  .khq-data-filter > .navbar-collapse {
    order: 3;
  }
}

@media (min-width: 576px) {
  .khq-data-filter select {
    max-width: 170px;
  }
}

table tbody tr.khq-data-highlight-row:hover {
  background: none;
}

table tbody tr.khq-data-highlight-row pre {
  overflow: hidden;
  max-height: none;
  cursor: default;
}

table tbody tr.khq-data-highlight-row pre code:after {
  display: none;
}

.khq-offset-navbar {
  max-height: 400px;
  overflow: auto;
}

.khq-offset-navbar .offset-navbar-partition-label {
  white-space: nowrap;
  text-align: right;
  padding-right: 4px;
  width: 75px;
}

.khq-offset-navbar .offset-navbar-partition-td {
  padding-bottom: 10px;
}

.khq-offset-navbar .input-group {
  flex-direction: column;
}

.khq-offset-navbar .input-group table {
  width: 100%;
}

.khq-offset-navbar .input-group-append {
  margin-left: auto;
}

.khq-offset-navbar .input-group-append .btn {
  margin-right: 0;
  border-radius: 0.25rem;
}

.khq-offset-navbar .input-group .offset-navbar-partition-td .form-control::-webkit-inner-spin-button,
.khq-offset-navbar .input-group .offset-navbar-partition-td .form-control::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.khq-ace-editor > div {
  height: calc((1.5em + 0.75rem * 3));
}

.khq-ace-editor > textarea {
  display: none;
}

.khq-datetime, .khq-data-datetime {
  min-width: 18.5rem;
  padding-bottom: 0;
}

.khq-datetime .datetime-container .col-md-6, .khq-data-datetime .datetime-container .col-md-6 {
  max-width: none;
  flex: auto;
}

.khq-datetime .datetime-container .col-md-6 .timepicker-picker, .khq-data-datetime .datetime-container .col-md-6 .timepicker-picker {
  border-top: 2px solid #444;
}

.khq-datetime .bootstrap-datetimepicker-widget .timepicker-hour, .khq-datetime .bootstrap-datetimepicker-widget .timepicker-minute, .khq-datetime .bootstrap-datetimepicker-widget .timepicker-second, .khq-data-datetime .bootstrap-datetimepicker-widget .timepicker-hour, .khq-data-datetime .bootstrap-datetimepicker-widget .timepicker-minute, .khq-data-datetime .bootstrap-datetimepicker-widget .timepicker-second {
  width: 16px;
  font-weight: normal;
}

.khq-datetime .bootstrap-datetimepicker-widget table td, .khq-data-datetime .bootstrap-datetimepicker-widget table td {
  height: 16px;
  line-height: 16px;
  width: 16px;
}

.khq-datetime .bootstrap-datetimepicker-widget table td span, .khq-data-datetime .bootstrap-datetimepicker-widget table td span {
  display: inline-block;
  width: 16px;
  height: 16px;
  line-height: 16px;
}

.khq-datetime .input-group {
  padding: 0.375rem 0.75rem;
}

.khq-datetime .input-group input {
  border: 0;
}

.khq-multiple > div {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
}

.khq-multiple > div:last-child {
  margin-bottom: 0;
}

.khq-multiple > div > * {
  margin-right: 1rem;
}

.khq-multiple > div > *:last-child {
  margin-right: 0;
}

.khq-select > .dropdown-toggle.bs-placeholder {
  border: 0;
}

.khq-select .dropdown-menu .bs-searchbox {
  margin-bottom: 4px;
}

.khq-select .dropdown-item.selected {
  background: #33b5e5;
}

.khq-select .dropdown-item.selected span.fa {
  display: none !important;
}

div.khq-search-sse .progress-container {
  border-left: 1px solid #444;
  border-right: 1px solid #444;
  padding: 0.75rem;
  display: flex;
  flex-direction: row;
}

div.khq-search-sse .progress-container .progress {
  border-radius: 0;
  flex-grow: 2;
  margin-top: 0.375rem;
}

div.khq-search-sse .progress-container .progress .progress-bar {
  transition: width 0.1s ease;
  color: #0e5671;
}

div.khq-search-sse .progress-container button {
  margin-left: 0.75rem;
}

table tbody pre {
  white-space: pre-wrap;
  overflow-wrap: break-word;
  overflow: hidden;
  max-height: 100px;
  cursor: pointer;
  position: relative;
}

table tbody pre code {
  white-space: pre-wrap;
  overflow-wrap: break-word;
  word-break: break-all;
  display: block;
}

table tbody pre code:after {
  content: '';
  width: 100%;
  height: 35px;
  position: absolute;
  left: 0;
  bottom: 0;
  background: linear-gradient(transparent, #171819);
}

table tbody tr.fade-in {
  animation: fade-in 0.3s;
}

table tbody tr:hover pre code:after {
  background: linear-gradient(transparent, #116787);
}

table tbody tr.deleted {
  opacity: 0.5;
}

table tbody tr.reduce th, table tbody tr.reduce td {
  padding: 0.3rem 0.75rem;
}

table tbody code.key {
  font-size: 92.5%;
}

table tbody td a, table tbody td a:hover {
  color: #fff;
}

table tbody .table.table-sm {
  margin-top: calc(-0.75rem);
  width: calc(100% + (0.75rem * 2));
  margin-left: -0.75rem;
  position: relative;
  border-bottom: 1px dashed #444;
  font-size: 0.76562rem;
}

table tbody .table.table-sm td, table tbody .table.table-sm th {
  border: 0;
}

table tbody .table.table-sm th {
  border-right: 1px dashed #444;
}

table tbody .table.table-sm tbody tr:nth-of-type(even) {
  background-color: #303030;
}

table tbody .table.table-sm tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0);
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

table td.khq-row-action, table th.khq-row-action {
  width: 16px;
}

table tbody .khq-main-row-action {
  cursor: pointer;
}
