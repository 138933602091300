.khq-login {
    width: 100%;
    max-width: 330px;
    margin: 0 auto;
    text-align: center;

    padding: 40px;
    background: $gray-900;
    box-shadow: 0 0 40px 5px rgba($tertiary, 0.4);

    > div:first-child {
        margin-bottom: 40px;
    }
}