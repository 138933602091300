#khq-sidebar {
    min-width: $menu-width;
    max-width: $menu-width;
    background: $black;
    color: #fff;
    transition: all 0.3s;
    font-family: $font-family-title;
    font-size: $font-size-lg;
    border-right: 1px solid $nav-tabs-border-color;
    z-index: 2;
    display: flex;
    flex-direction: column;

    .sidebar-header {
        padding: 5px 10px;
        background: $tertiary;
        border-bottom: 4px solid $yellow;
        position:relative;
        a {
            &:hover {
                text-decoration: none;
            }
        }
        .version{
            position:absolute;
            right:5px;
            top:3px;
            font-size: $font-size-sm;
        }
    }

    ul {
        flex-grow: 2;
        p {
            color: #fff;
            padding: 10px;
        }
        li {
            a {
                padding: 10px;
                display: block;
                color: #FFFFFF;
                &:hover {
                    background: lighten($tertiary, 10%);
                    text-decoration: none;
                }
            }
            &.active > a {
                color: #fff;
                background: $tertiary;
            }

            ul {
                border-bottom: 1px solid lighten($tertiary, 15%);
                li a {
                    background: $black;

                    &.active {
                        background: $yellow;
                        color: $black;
                    }
                }
            }
        }
    }


    a {
        &[aria-expanded="true"] {
            color: #fff;
            background: $tertiary;
        }
        &[data-toggle="collapse"] {
            position: relative;
        }
    }

    .dropdown-toggle::after {
        display: block;
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
    }

    ul ul a {
        font-size: 0.9em !important;
        padding-left: 30px !important;
        background: $tertiary;
    }

    .sidebar-log {
        padding: 5px 10px;
        background: $tertiary;
        border-top: 1px solid $yellow;
        a {
            color: white;
            &:hover {
                text-decoration: none;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        & {
            margin-left: -$menu-width;
            &.active {
                margin-left: 0;
            }
        }
        #khq-sidebar-collapse span {
            display: none;
        }
    }

    @include media-breakpoint-up(md) {
        position: fixed;
        height: 100%;
    }
}
